import {TBreakpoints, TTierTitle} from "modules/types";
import {Breakpoint, LogoStripTier} from "modules/enums";

export const REGIONS: Record<string, string[]> = {
	North_Central_America: [
		"MX",
		"AG",
		"BS",
		"BB",
		"BZ",
		"CA",
		"CR",
		"CU",
		"DM",
		"DO",
		"SV",
		"GD",
		"GT",
		"HT",
		"HN",
		"JM",
		"NI",
		"PA",
		"KN",
		"LC",
		"VC",
		"TT",
		"US",
	],
	South_America: ["AR", "BO", "BR", "CL", "CO", "EC", "GY", "PY", "PE", "SR", "UY", "VE"],
	Africa_Middle_East: [
		"DZ",
		"AO",
		"BH",
		"BJ",
		"BW",
		"BF",
		"BI",
		"CM",
		"CV",
		"CF",
		"TD",
		"KM",
		"CD",
		"DR",
		"DJ",
		"EG",
		"GQ",
		"ER",
		"ET",
		"GA",
		"GM",
		"GH",
		"GN",
		"GW",
		"IR",
		"IQ",
		"IL",
		"CI",
		"JO",
		"KE",
		"KW",
		"LB",
		"LS",
		"LR",
		"LY",
		"MG",
		"MW",
		"ML",
		"MR",
		"MU",
		"MA",
		"MZ",
		"NA",
		"NE",
		"NG",
		"OM",
		"QA",
		"RW",
		"ST",
		"SA",
		"SN",
		"SC",
		"SL",
		"SO",
		"ZA",
		"SS",
		"SD",
		"SZ",
		"SY",
		"TZ",
		"TG",
		"TN",
		"UG",
		"AE",
		"YE",
		"ZM",
		"Zimbabwe",
	],
	Asia: [
		"AF",
		"BD",
		"BT",
		"BN",
		"BU",
		"KH",
		"CN",
		"TL",
		"FG",
		"IN",
		"IN",
		"JP",
		"KR",
		"KG",
		"MY",
		"MV",
		"MN",
		"NR",
		"NP",
		"KP",
		"PK",
		"PH",
		"SG",
		"LA",
		"LK",
		"TH",
		"UZ",
		"VN",
	],
	Europe: [
		"AL",
		"AD",
		"AM",
		"AT",
		"AZ",
		"BY",
		"BE",
		"BA",
		"BG",
		"HR",
		"CY",
		"CZ",
		"DK",
		"EE",
		"FI",
		"FR",
		"GE",
		"DE",
		"GR",
		"HU",
		"IS",
		"IE",
		"IT",
		"KZ",
		"LV",
		"LI",
		"LT",
		"LU",
		"MK",
		"MT",
		"MD",
		"MC",
		"ME",
		"NL",
		"NO",
		"PL",
		"PT",
		"RO",
		"RU",
		"SM",
		"RS",
		"SK",
		"SI",
		"ES",
		"SE",
		"CH",
		"TJ",
		"TR",
		"TM",
		"UA",
		"GB",
		"VA",
	],
	Oceania: ["AU", "FJ", "KI", "MH", "FM", "NZ", "PW", "PG", "WS", "SB", "TO", "VU"],
};

export const TIERS: LogoStripTier[] = [
	LogoStripTier.FIFA_Presenting_Partner,
	LogoStripTier.FIFA_Partners,
	LogoStripTier.FIFA_Tournament_Partner,
	LogoStripTier.FIFA_Sponsors,
	LogoStripTier.FIFA_National_supporters,
];

export const TIER_TITLES: Record<LogoStripTier, TTierTitle> = {
	[LogoStripTier.FIFA_Partners]: {
		en: "FIFA Partners",
		fr: "FIFA Partners",
		de: "FIFA Partners",
		es: "FIFA Partners",
		pt: "FIFA Partners",
	},
	[LogoStripTier.FIFA_Sponsors]: {
		en: "Sponsors",
		fr: "Sponsors",
		de: "Sponsors",
		es: "Sponsors",
		pt: "Sponsors",
	},
	[LogoStripTier.FIFA_National_supporters]: {
		en: "Supporters",
		fr: "Supporters",
		de: "Supporters",
		es: "Supporters",
		pt: "Supporters",
	},
	[LogoStripTier.FIFA_Presenting_Partner]: {
		en: "Presenting Partner",
		fr: "Presenting Partner",
		de: "Presenting Partner",
		es: "Presenting Partner",
		pt: "Presenting Partner",
	},
	[LogoStripTier.FIFA_Tournament_Partner]: {
		en: "FIFA Women's Football Partners",
		fr: "FIFA Women's Football Partners",
		de: "FIFA Women's Football Partners",
		es: "FIFA Women's Football Partners",
		pt: "FIFA Women's Football Partners",
	},
};

export const SPONSORS_TIER_TITLES: Record<string, TTierTitle> = {
	FWWC: {
		en: "FIFA Women's World Cup Sponsors",
		fr: "FIFA Women's World Cup Sponsors",
		de: "FIFA Women's World Cup Sponsors",
		es: "FIFA Women's World Cup Sponsors",
		pt: "FIFA Women's World Cup Sponsors",
	},
};

export const SUPPORTERS_TIER_TITLES: Record<string, TTierTitle> = {
	FWWC: {
		en: "FIFA Women's World Cup Supporters",
		fr: "FIFA Women's World Cup Supporters",
		de: "FIFA Women's World Cup Supporters",
		es: "FIFA Women's World Cup Supporters",
		pt: "FIFA Women's World Cup Supporters",
	},
};

export const TOURNAMENT_TIER_TITLES: Record<string, TTierTitle> = {
	FWWC: {
		en: "FIFA Women's Football Partners",
		fr: "FIFA Women's Football Partners",
		de: "FIFA Women's Football Partners",
		es: "FIFA Women's Football Partners",
		pt: "FIFA Women's Football Partners",
	},
};

export const TIER_TITLE_MAP = {
	[LogoStripTier.FIFA_Sponsors]: SPONSORS_TIER_TITLES,
	[LogoStripTier.FIFA_National_supporters]: SUPPORTERS_TIER_TITLES,
	[LogoStripTier.FIFA_Tournament_Partner]: TOURNAMENT_TIER_TITLES,
};

export const TIER_COLUMN_MAP: Record<LogoStripTier, TBreakpoints> = {
	[LogoStripTier.FIFA_Partners]: {
		[Breakpoint.ExtraSmall]: 1,
		[Breakpoint.Small]: 5,
		[Breakpoint.Medium]: 7,
		[Breakpoint.Large]: 8,
		[Breakpoint.ExtraLarge]: 10,
	},
	[LogoStripTier.FIFA_Sponsors]: {
		[Breakpoint.ExtraSmall]: 1,
		[Breakpoint.Small]: 7,
		[Breakpoint.Medium]: 9,
		[Breakpoint.Large]: 10,
		[Breakpoint.ExtraLarge]: 10,
	},
	[LogoStripTier.FIFA_National_supporters]: {
		[Breakpoint.ExtraSmall]: 1,
		[Breakpoint.Small]: 7,
		[Breakpoint.Medium]: 9,
		[Breakpoint.Large]: 10,
		[Breakpoint.ExtraLarge]: 10,
	},
	[LogoStripTier.FIFA_Presenting_Partner]: {
		[Breakpoint.ExtraSmall]: 1,
		[Breakpoint.Small]: 5,
		[Breakpoint.Medium]: 7,
		[Breakpoint.Large]: 8,
		[Breakpoint.ExtraLarge]: 10,
	},
	[LogoStripTier.FIFA_Tournament_Partner]: {
		[Breakpoint.ExtraSmall]: 1,
		[Breakpoint.Small]: 6,
		[Breakpoint.Medium]: 8,
		[Breakpoint.Large]: 9,
		[Breakpoint.ExtraLarge]: 10,
	},
};
