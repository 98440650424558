export enum LogoStripTier {
	FIFA_Partners = "FIFA_Partners",
	FIFA_Sponsors = "FIFA_Sponsors",
	FIFA_National_supporters = "FIFA_National_supporters",
	FIFA_Presenting_Partner = "FIFA_Presenting_Partner",
	FIFA_Tournament_Partner = "FIFA_Tournament_Partner",
}

export enum Theme {
	Light = "Light_background",
	Dark = "Dark_background",
}

export enum LanguageCode {
	English = "en",
	French = "fr",
	German = "de",
	Spanish = "es",
	Portuguese = "pt",
}

export enum Breakpoint {
	ExtraSmall,
	Small,
	Medium,
	Large,
	ExtraLarge,
}
