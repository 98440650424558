import style from "./style.css";

const template = document.createElement("template");

template.innerHTML = `
    <div class="wrapper">
        <h1 class="title">Custom Header Component</h1>
    </div>
`;

// Create a class for the element
class CustomHeader extends HTMLElement {
	constructor() {
		// Always call super first in constructor
		super();

		// Create a shadow root
		const shadow = this.attachShadow({mode: "open"});

		// Apply external styles to the shadow dom
		const styleTag = document.createElement("style");
		styleTag.innerHTML = style;

		// Attach the created elements to the shadow dom
		shadow.appendChild(styleTag);
		shadow.appendChild(template.content.cloneNode(true));
	}
}

// Define the new element
customElements.define("custom-header", CustomHeader);
